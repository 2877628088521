import axios from 'axios';
import { today } from '@/utility/date';

export interface DeadlineSettingItem {
  deadlineId: number;
  deadlineName: string;
  deadlineDay: number;
}

export enum EDeadline {
  SSchedule = 1, // 従事者予定入力期限 / 毎日 現在日から遡る「10日間」より前は入力できない
  SResult = 2, //  従事者実績入力期限 / 毎日 現在日から遡る「10日間」より前は入力できない
  SPaidH = 3, //  休暇消化管理 / 毎月 現在月の「10日」以降は前月の入力できない
  RMethod = 4, //  交通手段入力 / 毎月 現在月の「10日」以降は前月の入力できない
  CSchedule = 5, //  児童予定入力期限 / 毎日 現在日から遡る「10日間」より前は入力できない
  CResult = 6, //  児童実績入力期限 / 毎日 現在日から遡る「10日間」より前は入力できない
  SRequestGl = 7, //  シフト希望入力期限_GL / 毎月 拠点基準日から遡る「10日間」以降は次月の入力できない FrontEndでは使われていない
  SRequest = 8, //  シフト希望入力期限 / 毎月 拠点基準日から遡る「10日間」以降は次月の入力できない FrontEndでは使われていない
}

export type DeadlineSetting = DeadlineSettingItem;

export async function loadDeadlines(): Promise<DeadlineSetting[]> {
  const data = (await axios.get<DeadlineSettingItem[]>('/api/deadline/')).data;
  const res = data.map(v => v as DeadlineSetting);
  return res;
}

const countTypeIds = [EDeadline.SSchedule, EDeadline.SResult, EDeadline.CSchedule, EDeadline.CResult];

export function isEditableDeadline(deadlines: DeadlineSetting[], id: EDeadline, targetDate: Date): boolean {
  const find = deadlines.find(v => v.deadlineId === id);
  if (find === undefined) return false;

  const td = today();
  if (id === EDeadline.SPaidH || id === EDeadline.RMethod) {
    //  日付判定
    const day = find.deadlineDay;
    const targetYm = targetDate.getFullYear() * 12 + targetDate.getMonth();
    const todayYm = td.getFullYear() * 12 + td.getMonth();
    const m = td.getDate() < day ? 2 : 1;
    return !(targetYm - todayYm <= -m);
  } else if (countTypeIds.includes(id)) {
    //  日数判定 一定の日数より前は入力できない
    const dayCount = find.deadlineDay;
    const days = (targetDate.getTime() - td.getTime()) / 1000 / 3600 / 24;
    return !(days < -dayCount);
  }
  return false;
}

//  締め日の取得 ※SResult CResult専用
export function getDeadlineDay(deadlines: DeadlineSetting[], id: EDeadline): Date {
  const find = deadlines.find(v => v.deadlineId === id);
  const days = find?.deadlineDay ?? 0;
  const date = today();
  date.setDate(date.getDate() - days);
  return date;
}
