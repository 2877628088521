import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { axiosConnections } from './axios-lib-utilities';

export async function polling<T>(
  commonUrl: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> {
  const stamp = await axios.post<string>(commonUrl + '-set', data, {
    ...config,
    responseType: 'text',
  });
  const getUrl = commonUrl + '-get';
  const getConfig = {
    params: { stamp: stamp.data },
    responseType: config?.responseType,
  };
  try {
    axiosConnections.value++;
    for (;;) {
      const response = await axios.get<T>(getUrl, getConfig);
      if (response.status === 204) continue;
      return response;
    }
  } finally {
    axiosConnections.value--;
  }
}

/// responseType: "blob"であるファイル取得の通信結果をダウンロードする
export function downloadFromResponse(response: AxiosResponse<Blob>, contentType = '') {
  if (contentType && response.data.type.toUpperCase() !== contentType.toUpperCase()) {
    // 目的のファイル以外を受け取った
    throw response.data;
  }
  // ファイル名の取得
  // 参照 https://developer.mozilla.org/ja/docs/Web/HTTP/Headers/Content-Disposition
  if (!('content-disposition' in response.headers)) {
    // 可能性としては
    // 1.CORSのために見えない状態になっている
    // 2.レスポンスヘッダーにContent-Dispositionがないか
    // 対策としては
    // 1 => AppControllerBase.FileResultを使うよう修正する
    // 2 => ファイル以外のデータが送られているので、
    //   想定しているAPIが使われているか確認し、ファイル以外のレスポンスになっていないか確認する
    throw 'no content-disposition in response headers';
  }
  const contentDisposition = response.headers['content-disposition'] as string;
  let name = '';
  if (contentDisposition) {
    const aster = contentDisposition.match(/ filename\*=UTF-8''((%[0-9A-F]{2}|[^\\/:*?"<>|])+)($|;)/);
    // 一つのヘッダに filename と filename* の両方が存在している場合、
    // filename* は filename より優先される
    if (aster) {
      name = decodeURI(aster[1]);
    } else {
      const matches = contentDisposition.match(/filename="(.*)"/);
      name = matches ? matches[1] : '';
    }
  }
  if (!name) {
    // ファイル名の指定がない可能性がある
    throw 'no file name';
  }
  const blob = response.data;
  saveAs(blob, name);
}

export const saveAs = (blob: Blob, name: string) => {
  const stamp = Date.now().toString(36);
  const file = name.match(/(.+)(\.\w+$)/);
  if (file) {
    name = file[1] + '-' + stamp + file[2];
  } else {
    name += '-' + stamp;
  }
  // アンカー要素(<a>)を生成してクリック
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', name);
  a.click();
  URL.revokeObjectURL(url);
};

export async function axiosGetWithoutSpin<T>(url: string, config?: AxiosRequestConfig) {
  axiosConnections.value--;
  try {
    return await axios.get<T>(url, config);
  } finally {
    axiosConnections.value++;
  }
}

export async function axiosGetWithoutSpinWithData<T>(url: string, object: object) {
  axiosConnections.value--;
  try {
    return await axios.post<T>(url, object); //< Getだとサポート外とエラー Postで代用
  } finally {
    axiosConnections.value++;
  }
}
