import axios from 'axios';

//  --- Pid
//  体調管理
export const PidCHealthCase = 110;
export const PidCHealthSituation = 120;
export const PidCHealthAction = 130;
export const PidCHealthContact = 140;

//  報連相
export const PidDInformTitle = 210;
export const PidDInformItem = 220;
export const PidDInformContents = 230;

//  vue ⇔ backend
export interface PulldownListItem {
  pId: number;
  pName: string;
  rowItems: PulldownListRowItem[];
}

export interface PulldownListRowItem {
  no: number;
  pNo: number;
  pCcontents: string;
}

//  vue control
export interface PulldownList extends Omit<PulldownListItem, 'rowItems'> {
  rows: PulldownListRow[];
}

export interface PulldownListRow extends PulldownListRowItem {
  editStatus: 0 | 1;
}

export function initPulldownList(pId: number): PulldownList {
  const list: PulldownList = {
    pId: pId,
    pName: '新規リスト',
    rows: [],
  };
  return list;
}

//  行の追加
export function addEditRow(editRows: PulldownListRow[]) {
  const nos = editRows.map(v => v.no);
  const pnos = editRows.map(v => v.pNo);

  const minNo =
    Math.min(
      nos.reduce((a, b) => Math.min(a, b), 0),
      0
    ) - 1;
  const maxPNo =
    Math.min(
      pnos.reduce((a, b) => Math.max(a, b), 0),
      0
    ) + 1;

  const row: PulldownListRow = {
    no: minNo,
    pNo: maxPNo,
    pCcontents: '',
    editStatus: 1,
  };
  editRows.push(row);
}

// 項目名 最大文字列長
const pCcontentsMaxLength = 20;

//  pContents
export function isValidPCcontents(pCcontents: string) {
  return pCcontents.length > 0 && pCcontents.length < pCcontentsMaxLength;
}

export function isValidEditRows(editRows: PulldownListRow[]) {
  return !editRows.some(v => v.editStatus === 1);
}

export async function save(list: PulldownList, rows: PulldownListRow[]) {
  const item: PulldownListItem = {
    ...list,
    rowItems: [...rows],
  };
  await axios.put('/api/pulldownlist/pid/' + list.pId, item);
}

export async function load(pId: number): Promise<PulldownList> {
  const response = await axios.get<PulldownListItem>('/api/pulldownlist/pid/' + pId);
  if (response.data === null) {
    const list: PulldownList = {
      pId: pId,
      pName: '',
      rows: [],
    };
    return list;
  }

  const rows: PulldownListRow[] = response.data.rowItems.map(ritem => {
    const row: PulldownListRow = {
      ...ritem,
      editStatus: 0,
    };
    return row;
  });
  const list: PulldownList = {
    ...response.data,
    rows: rows,
  };
  return list;
}
