import { ListOption } from '@/utility/ListOptions'; // 管理会社毎の情報

export const komaMin = 15;
export const komaDenom = 60 / komaMin;
export const koma24 = komaDenom * 24; //< 24時間分のコマ数

export const marginFromMin = 1; //< 1分までの遅刻は、一分遡る
export const marginToMin = 1; //< 1分までの早期帰宅は、一分進む

export const komaMins = Array.from({ length: komaDenom }).map((_, i) => i * komaMin);

export const initTime = '07:00';
export const initKomaIndex = 7 * komaDenom;

export const classAll = '全て';

export const maintSCode = 664;

export function getHour24Opts(): ListOption<number>[] {
  const res = Array.from({ length: 24 }).map((_, i) => {
    const opt: ListOption<number> = {
      value: i,
      text: i.toString().padStart(2, '0') + '時',
    };
    return opt;
  });
  return res;
}

export function getMinOpts(): ListOption<number>[] {
  const res = komaMins.map(v => {
    const opt: ListOption<number> = {
      value: v,
      text: v.toString().padStart(2, '0') + '分',
    };
    return opt;
  });
  return res;
}

//  暫定でここに
export const RMoneyStatus = {
  none: 0,
  money: 1,
  noData: 2,
  noCalc: 3,
} as const;
