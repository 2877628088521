import axios from 'axios';
import { LoginUser, LoginUserItem } from '@/utility/DTO/LoginUser';
import { toDateKey } from '@/utility/date';
import { bCodeLength, bCodeBaseLength } from '@/utility/consts/DbTables';
import { ListOption } from './ListOptions';
import { SssStaffOpt, SssBaseOpt } from '@/utility/SelectStaffSearch';
import { DeadlineSettingItem, DeadlineSetting } from '@/utility/deadline-setting';

export interface BasicItem {
  bases: BaseOptionItem[];
  loginUser: LoginUserItem;
  staffs: StaffOptionItem[];
  deadlines: DeadlineSettingItem[];
}

interface BaseOptionItem {
  bcode: string;
  bkname: string;
  office: boolean; //< Bjtype === 8
  btagno: number;
  sneedctgys: number[]; //< 必要な業務区分
  btocalc06: number; //< 保育補助員枠の許容基準、00＝不可、01＝子育て支援員なら可、02＝無資格者でも可
}

interface StaffOptionItem {
  scode: number;
  sname: string;
  stagno: number;
  bcodes: string[];
}

export type BaseOption = BaseOptionItem;

export interface BasicData {
  baseOptions?: Record<string, BaseOption>;
  loginUser: LoginUser;
  sssStaffOpts: SssStaffOpt[];
  sssBaseOpts: SssBaseOpt[]; //< 全拠点名 暫定 computedに切替 zzz
  deadlines: DeadlineSetting[];
}

export const initBasicData: BasicData = {
  baseOptions: undefined,
  loginUser: {
    sEmployee: 0,
    sCategory: 0,
    sPosition: 0,
    sMainBase: '',
    sSubBase: '',
    bgCode: '',
    bgName: '',
    bgBCodes: [],
  },
  sssStaffOpts: [],
  sssBaseOpts: [],
  deadlines: [],
};

export async function loadBasic(basic: BasicData, targetDate: Date) {
  const response = await axios.get<BasicItem>('/api/MasterBase/basic/' + toDateKey(targetDate));

  //  mbeases
  const rec: Record<string, BaseOption> = {};
  response.data.bases.forEach(v => {
    const opt: BaseOption = {
      ...v,
    };
    rec[v.bcode] = opt;
  });
  basic.baseOptions = rec;

  //  user情報
  basic.loginUser = { ...response.data.loginUser };

  //  SssStaffOpt
  basic.sssStaffOpts = response.data.staffs.map(v => {
    const opt: SssStaffOpt = {
      sCode: v.scode,
      sName: `${v.scode} ${v.sname}`,
      sTagNo: v.stagno,
      bCodes: [...v.bcodes],
    };
    return opt;
  });

  //  SssBaseOpt 暫定
  basic.sssBaseOpts = response.data.bases.map(v => {
    const opt: SssBaseOpt = {
      bCode: v.bcode,
      bNTiny: v.bkname,
      bTagNo: v.btagno,
    };
    return opt;
  });

  //  deadline
  basic.deadlines = [...response.data.deadlines];
}

//  画面左上の拠点選択のリストを生成 従事者により異なる
export function getSelectableBaseOpts(basic: BasicData): ListOption<string>[] {
  if (!basic.baseOptions) return [];
  const isOffice = isOfficeUser(basic);

  const loginUser = basic.loginUser;

  //  拠点グループCode由来の拠点を追加 拠点グループ
  const bCodesByBgcode: string[] = loginUser.bgCode
    ? loginUser.bgCode.indexOf('Z') === 0
      ? [...loginUser.bgBCodes] //< 拠点グループ
      : [loginUser.bgCode] //< 一つの拠点
    : []; //< なし

  //  SMainBase,SSubBase由来の重複なしのbCode
  const set = new Set<string>();
  set.add(loginUser.sMainBase.slice(0, bCodeBaseLength));
  if (loginUser.sSubBase.length === bCodeLength) {
    set.add(loginUser.sSubBase.slice(0, bCodeBaseLength));
  }
  const baseList: string[] = [...set];

  //  拠点リストに登録
  const res: ListOption<string>[] = bCodesByBgcode.map(bCode => {
    const bopt: ListOption<string> = {
      value: basic.baseOptions?.[bCode].bcode ?? '',
      text: basic.baseOptions?.[bCode].bkname ?? '',
    };
    return bopt;
  });

  Object.keys(basic.baseOptions)
    .filter(bCode => isOffice || baseList.includes(bCode.slice(0, bCodeBaseLength)))
    .filter(bCode => !bCodesByBgcode.includes(bCode))
    .forEach(bCode => {
      const bopt: ListOption<string> = {
        value: basic.baseOptions?.[bCode].bcode ?? '',
        text: basic.baseOptions?.[bCode].bkname ?? '',
      };
      res.push(bopt);
    });

  return res;
}

export function getBaseRecord(basic: BasicData): Record<string, string> {
  if (!basic.baseOptions) return {};

  const rec: Record<string, string> = {};
  Object.keys(basic.baseOptions).forEach(bCode => {
    if (basic.baseOptions) rec[bCode] = basic.baseOptions[bCode].bkname;
  });
  return rec;
}

export function isOfficeUser(basic: BasicData): boolean {
  if (!basic.baseOptions) return false;
  return (
    basic.baseOptions[basic.loginUser.sMainBase].office ||
    (basic.loginUser.sSubBase.length > 0 && basic.baseOptions[basic.loginUser.sSubBase].office)
  );
}

export function getBaseOpts(basic: BasicData): ListOption<string>[] {
  if (!basic.baseOptions) return [];

  const opts: ListOption<string>[] = Object.keys(basic.baseOptions)?.map(bCode => {
    const name = !basic.baseOptions ? '-' : basic.baseOptions[bCode as keyof ListOption<string>].bkname ?? '-';
    const res: ListOption<string> = {
      value: bCode,
      text: name,
    };
    return res;
  });
  return opts;
}

export function getCates(basic: BasicData, bCode: string): number[] | undefined {
  if (!basic.baseOptions) return undefined;
  return basic.baseOptions[bCode].sneedctgys.filter(v => v > 0);
}

export function getCategoryOpts(basic: BasicData, bCode: string, allCateOpts: ListOption<number>[]): ListOption<number>[] {
  if (!basic.baseOptions) return [];
  const cateIds = basic.baseOptions[bCode]?.sneedctgys ?? [0];
  return allCateOpts.filter(v => cateIds.some(x => x === v.value));
}

export function getExtendBaseOpts(
  allBaseOpts: ListOption<string>[],
  selectableBaseOpts: ListOption<string>[],
  bCode: string
): ListOption<string>[] {
  if (!bCode || selectableBaseOpts.find(v => v.value === bCode)) return selectableBaseOpts;
  const find = allBaseOpts.find(v => v.value === bCode);
  if (!find) return selectableBaseOpts;
  const opts = [...selectableBaseOpts];
  opts.push(find);
  return opts;
}
