export {};

declare global {
  interface StringConstructor {
    isNullOrEmpty(str: string): boolean;
  }
  interface String {
    zeropad(num: number): string;
  }
}

String.isNullOrEmpty = function (str: string): boolean {
  if (str === undefined || str === null || str.trim() === '') {
    return true;
  } else {
    return false;
  }
};
String.prototype.zeropad = function (num: number): string {
  return this.padStart(num, '0');
};
