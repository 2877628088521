import axios from 'axios';
import { ListOption, ListOptionNum } from './ListOptions';

export interface ClassificationItem {
  kcode: string;
  kinid: number;
  kname: string;
  kntiny: string;
}

export type Classification = ClassificationItem;

export async function loadClassification(kcodes: string[]): Promise<Classification[]> {
  const response = await axios.get<ClassificationItem[]>('/api/MasterClassification/', {
    params: {
      kcodes: kcodes.join(','),
    },
  });
  return response.data;
}

export function createListOptionNum(items: ClassificationItem[], kcode: string): ListOptionNum[] {
  const res = items
    .filter(v => v.kcode === kcode)
    .map(v => {
      const opt: ListOptionNum = {
        value: v.kinid,
        text: v.kntiny,
      };
      return opt;
    });
  return res;
}

export function createListOptionStr(items: ClassificationItem[], kcode: string): ListOption<string>[] {
  const res = items
    .filter(v => v.kcode === kcode)
    .map(v => {
      const opt: ListOption<string> = {
        value: v.kntiny,
        text: v.kntiny,
      };
      return opt;
    });
  return res;
}
