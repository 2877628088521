import { sPwMinLength, sPwMaxLength } from '@/utility/consts/DbTables';

export function isValidPassword(pw: string): boolean {
  const str = pw.trim();

  if (str.length < sPwMinLength || str.length > sPwMaxLength) {
    return false;
  }

  // パスワードが英字と数字を含んでいることを確認
  const hasNumber = /\d/;
  const hasLetter = /[a-zA-Z]/;
  if (!hasNumber.test(str) || !hasLetter.test(str)) {
    return false;
  }

  // すべてのチェックがパスした場合は、パスワードが有効であると判断
  return true;
}
