export function validClass(check: boolean) {
  return check ? '' : 'ng';
}

export function toValidClassNum(num: number): string {
  return num > 0 ? '' : 'ng';
}

export function isFloat(value: string): boolean {
  return !Number.isNaN(parseFloat(value));
}

export function isInteger(value: number | string): boolean {
  if (typeof value === 'string') {
    return Number.isInteger(Number(value));
  }
  return Number.isInteger(value);
}

export function isValidLength(str: string, maxLength: number): boolean {
  return str.length > 0 && str.length < maxLength + 1;
}

export function isEmptyOrFloat(str: string | undefined | null): boolean {
  if (str === undefined || str === null || str.length === 0) return true;
  return !Number.isNaN(parseFloat(str));
}

export function isEmptyOrIntger(str: string | undefined | null): boolean {
  if (str === undefined || str === null || str.length === 0) return true;
  return Number.isInteger(Number(str));
}

export function isValidMail(mail: string, mailLength: number): boolean {
  const str = mail.trim();
  if (str.length > mailLength) return false;
  const res = str.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/);
  return res !== null;
}

export function isValidTel(tel: string, telLength: number): boolean {
  const str = tel.trim();
  if (str.length > telLength) return false;
  const res = str.match(/^(0[5-9]0-[0-9]{4}-[0-9]{4}|0[0-9]{1,3}-[0-9]{2,4}-[0-9]{4})$/);
  return res !== null;
}
