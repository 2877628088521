export function splitName(name: string): [string, string] {
  const strs = name.split(' ');
  if (strs.length === 1) return [name, ''];
  return [strs[0], strs.slice(1).join(' ')];
}

export function toName(nameA: string, nameB: string): string {
  const tNameA = collectSpace(nameA.trim().replaceAll('　', ' '));
  const tNameB = collectSpace(nameB.trim().replaceAll('　', ' '));
  return `${tNameA} ${tNameB}`;
}

function collectSpace(str: string): string {
  const strs = str.split(' ');
  const res = strs.filter(v => v.length > 0).join(' ');
  return res;
}
