import { komaMin, komaDenom } from '@/utility/consts/ComProps';

//  2023-06-20
export function toDateKey(date: Date): string {
  return `${date.getFullYear()}-${('00' + (date.getMonth() + 1)).slice(-2)}-${('00' + date.getDate()).slice(-2)}`;
}

//  06月20日
export function toMMdd(date: Date): string {
  return `${('00' + (date.getMonth() + 1)).slice(-2)}月${('00' + date.getDate()).slice(-2)}日`;
}

//  06/20 15:30
export function toMMddhhmm(date: Date): string {
  const mm0 = ('00' + (date.getMonth() + 1)).slice(-2);
  const dd = ('00' + date.getDate()).slice(-2);
  const hh = ('00' + date.getHours()).slice(-2);
  const mm1 = ('00' + date.getMinutes()).slice(-2);
  return `${mm0}/${dd} ${hh}:${mm1}`;
}

//  2023/06/20 15:30:18
export function toYmdTime(date: Date): string {
  const mm0 = ('00' + (date.getMonth() + 1)).slice(-2);
  const dd = ('00' + date.getDate()).slice(-2);
  const hh = ('00' + date.getHours()).slice(-2);
  const mm1 = ('00' + date.getMinutes()).slice(-2);
  const ss = ('00' + date.getSeconds()).slice(-2);
  return `${date.getFullYear()}/${mm0}/${dd} ${hh}:${mm1}:${ss}`;
}

//  2023-06-20 15:30:18
export function toIYmdTime(date: Date): string {
  return toYmdTime(date).replaceAll(' ', 'T').replaceAll('/', '-');
}

//  2023-06-20
export function toIYmd(date: Date): string {
  return toDateKey(date);
}

//  2023/06/20 15:30
export function toYmdHm(date: Date): string {
  const m0 = (date.getMonth() + 1).toString().padStart(2, '0');
  const d = date.getDate().toString().padStart(2, '0');
  const h = date.getHours().toString().padStart(2, '0');
  const m1 = date.getMinutes().toString().padStart(2, '0');
  return `${date.getFullYear()}/${m0}/${d} ${h}:${m1}`;
}

//  2023/06/20
export function toYmd(date: Date): string {
  const m0 = (date.getMonth() + 1).toString().padStart(2, '0');
  const d = date.getDate().toString().padStart(2, '0');
  return `${date.getFullYear()}/${m0}/${d}`;
}

//  15:30
export function toHHMM(date: Date) {
  return ('00' + date.getHours()).slice(-2) + ':' + ('00' + date.getMinutes()).slice(-2);
}

export function timeToIndex(time: string): number | undefined {
  const st = time.split(':');
  const res = st.length === 2 ? 4 * Number(st[0]) + Math.floor(Number(st[1]) / 15) : undefined;
  return res;
}

export function indexToTime(index: number): string {
  if (index < 0) return '';
  const hh = ('00' + Math.floor(index / komaDenom)).slice(-2);
  const mm = ('00' + komaMin * (index % komaDenom)).slice(-2);
  const res = `${hh}:${mm}`;
  return res;
}

export function compDate(dateA: Date, dateB: Date): boolean {
  return dateA.getFullYear() === dateB.getFullYear() && dateA.getMonth() === dateB.getMonth() && dateA.getDate() === dateB.getDate();
}

export const isValidDate = (date: Date) => !Number.isNaN(date.getTime());

export function toDate(date: Date): Date {
  return new Date(date.toDateString());
}

export function toDateSec(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
}

export function toYm(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function toYmText(date: Date): string {
  return `${date.getFullYear()}年 ${date.getMonth() + 1}月`;
}

//  2023-04-17 02:02:02.500 → Date
export function dtTextToDate(text: string): Date | undefined {
  const dtTexts = text.split('.');
  const res = dtTexts[0].match(/^([0-9]{4})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])\s([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/);
  return res ? new Date(Number(res[1]), Number(res[2]) - 1, Number(res[3]), Number(res[4]), Number(res[5]), Number(res[6])) : undefined;
}

//  2023-04-26 → Date 2023/04/26 → Date
export function dateTextToDate(text: string): Date | undefined {
  const res = text.trim().match(/^([0-9]{4})[-/](0[1-9]|1[0-2])[-/](0[1-9]|[12][0-9]|3[01])$/);
  return res ? new Date(Number(res[1]), Number(res[2]) - 1, Number(res[3]), 0, 0, 0) : undefined;
}

declare global {
  interface String {
    dtTextToDate(): Date;
    dateTextToDate(): Date;
  }

  interface Date {
    addDate(): Date;
  }
}

String.prototype.dtTextToDate = function (): Date {
  const res = dtTextToDate(this as string);
  if (!res) {
    throw `日付として解析できません ${this as string}`;
  }
  return res;
};

String.prototype.dateTextToDate = function (): Date {
  const res = dateTextToDate(this as string);
  if (!res) {
    throw `日付として解析できません ${this as string}`;
  }
  return res;
};

export function addDate(date: Date, add: number): Date {
  const dt = new Date(date);
  dt.setDate(dt.getDate() + add);
  return dt;
}

//  月に属するDateの全てを取得
export function getDatesAtMonth(targetDate: Date): Date[] {
  const minDt = new Date(targetDate.getFullYear(), targetDate.getMonth(), 1, 0, 0, 0);
  const maxDt = new Date(minDt);
  maxDt.setMonth(maxDt.getMonth() + 1);

  const days = Math.floor((maxDt.getTime() - minDt.getTime()) / 86400000);

  const dt = new Date(minDt);
  const dates = Array.from({ length: days }).map(() => {
    const date = new Date(dt);
    dt.setDate(dt.getDate() + 1);
    return date;
  });
  return dates;
}

export function today(): Date {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
}
