import axios from 'axios';
import { information } from '@/utility/messages';
import { downloadFromResponse, polling } from '@/utility/axios-utilities';

// --- transfer
interface MaintResultItem {
  messages: string[];
}

export async function checkMaintMTable(target: string): Promise<string[]> {
  const msgs = (await axios.get<MaintResultItem>(`/api/maint/${target}/`)).data.messages;
  return msgs;
}

export async function maintMTable(target: string) {
  axios.put<MaintResultItem>(`/api/maint/${target}/`).then(response => {
    const msgs = response.data.messages.join('\n');
    information(msgs);
  });
}

export async function checkMaintMStaffBase() {
  axios.get<MaintResultItem>('/api/maint/mstaffbase/').then(response => {
    const msgs = response.data.messages.join('\n');
    information(msgs);
  });
}

// --- メンテ N1Needs
export async function maintN1Needs(fromText: string, toText: string) {
  await axios
    .put<MaintResultItem>('/api/maint/n1needs', null, {
      params: {
        from: fromText,
        to: toText,
      },
    })
    .then(response => {
      const msgs = response.data.messages.join('\n');
      information(msgs, 'width: auto; min-width: 300px;');
    });
}

//  N1NeedsのCSVダウンロード
export async function output(fromText: string, toText: string) {
  const data = {
    from: fromText,
    to: toText,
    keep: true,
  };

  const response = await polling<Blob>('/api/exports/excel', data, {
    params: { name: 'ExcelN1Needs' },
    responseType: 'blob',
  });
  downloadFromResponse(response);
}

export async function resetPw(scode?: number, pw?: string) {
  await axios
    .post<MaintResultItem>('/api/maint/resetPw', null, {
      params: {
        scode: scode,
        pw: pw,
      },
    })
    .then(response => {
      const msgs = response.data.messages.join('\n');
      information(msgs, 'width: auto');
    });
}
