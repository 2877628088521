import { toDateKey } from '@/utility/date';
import { ListOption } from '@/utility/ListOptions';
import { komaMin } from '@/utility/consts/ComProps';
import { cPunchTypeFrom, cPunchTypeTo } from '@/utility/consts/DbTables';
import { ListOptionNum } from '@/utility/ListOptions';
import { getSelectableOutPunchTime, toResultInPunchTime } from '@/utility/Koma';

export interface RepunchCtrl {
  disp: boolean;
  punchType: number; //< 4| 6
  code: number;
  name: string;
  dateText: string;
  hour: number;
  minute: number;
  lastInPunchTime?: Date; //< 最後の出勤打刻 undefinedの時、選択可能な時刻は現時刻以前の無制限
  lastOutPunchTime?: Date; //< 最後の退勤打刻
  sCategory?: number;
  categoryOpts?: ListOptionNum[];
  isChild: boolean;
}

export const initRepunchCtrl: RepunchCtrl = {
  disp: false,
  punchType: 0,
  code: 0,
  name: '',
  dateText: toDateKey(new Date()),
  hour: 0,
  minute: 0,
  lastInPunchTime: undefined, //< 選択可能な時刻は、無制限
  isChild: false,
};

export function openRepunchDialog(
  ctrl: RepunchCtrl,
  inPunch: boolean,
  code: number,
  name: string,
  isChild: boolean,
  initDate: Date,
  lastIn?: Date,
  lastOut?: Date,
  sCategory?: number,
  categoryOpts?: ListOptionNum[]
): void {
  ctrl.punchType = inPunch ? cPunchTypeFrom : cPunchTypeTo;
  ctrl.code = code;
  ctrl.name = name;
  ctrl.isChild = isChild;
  ctrl.dateText = toDateKey(initDate);
  ctrl.hour = -1;
  ctrl.minute = -1;
  ctrl.lastInPunchTime = lastIn;
  ctrl.lastOutPunchTime = lastOut;
  ctrl.sCategory = sCategory;
  ctrl.categoryOpts = categoryOpts;
  ctrl.disp = true;
}

export function okAddRepunchDialog(ctrl: RepunchCtrl) {
  ctrl.disp = false;
}

export function cancelAddRepunchDialog(ctrl: RepunchCtrl) {
  ctrl.disp = false;
}

export function hourOpts(): ListOption<number>[] {
  const res = Array.from({ length: 24 }).map((_, i) => {
    const rec: ListOption<number> = {
      value: i,
      text: i.toString().padStart(2, '0'),
    };
    return rec;
  });
  return res;
}

export function minuteOpts(): ListOption<number>[] {
  const res = Array.from({ length: 4 }).map((_, i) => {
    const rec: ListOption<number> = {
      value: komaMin * i,
      text: (komaMin * i).toString().padStart(2, '0'),
    };
    return rec;
  });
  return res;
}

export function initRepunchTitles(fromTitle: string, toTitle: string): Record<number, string> {
  const res: Record<number, string> = {};
  res[cPunchTypeFrom] = fromTitle;
  res[cPunchTypeTo] = toTitle;
  return res;
}

export function isValidDate(ctrl: RepunchCtrl): boolean {
  if (!ctrl.dateText || ctrl.hour < 0 || ctrl.minute < 0) return false;

  const dts = ctrl.dateText.split('-');
  const inputDate = new Date(Number(dts[0]), Number(dts[1]) - 1, Number(dts[2]), ctrl.hour, ctrl.minute, 0);

  const now = new Date();
  if (ctrl.punchType === cPunchTypeFrom) {
    //  --- 出勤打刻忘れ
    //  誤り ... 前回の退勤打刻日時より前の日時か、現在時よりあと
    //           → 前回の退勤時刻 <= OK <= Now
    const lastOut =
      ctrl.lastOutPunchTime === undefined ||
      (ctrl.lastOutPunchTime !== undefined && ctrl.lastOutPunchTime.getTime() <= inputDate.getTime());
    return lastOut && inputDate.getTime() <= now.getTime();
  } else {
    //  --- 退勤打刻忘れ
    //  誤り ... 現在の出勤打刻日時より前の日時か、出勤打刻日時の24時間以上あと
    //           → (出勤打刻 <= OK < 出勤打刻 + 24h) and (OK <= Now)  ※24hを含むとKomaが同時刻となるためNG

    if (!ctrl.lastInPunchTime) {
      return inputDate.getTime() <= now.getTime();
    } else {
      const adjustInPunchTime = toResultInPunchTime(ctrl.lastInPunchTime);
      const maxDt = getSelectableOutPunchTime(ctrl.lastInPunchTime);
      return (
        inputDate.getTime() <= now.getTime() && //< 現時刻以前
        adjustInPunchTime.getTime() <= inputDate.getTime() && //< 最後の打刻<= OK <= 選べる時刻
        inputDate.getTime() <= maxDt.getTime()
      );
    }
  }
}

export function isValidCategory(ctrl: RepunchCtrl): boolean {
  if (ctrl.isChild || ctrl.punchType === 6) return true; //< 児童又は退勤
  if (!ctrl.categoryOpts || !ctrl.sCategory) return false;
  return ctrl.categoryOpts.some(v => v.value === ctrl.sCategory);
}
