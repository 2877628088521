import { Store } from 'vuex';
import { getLoginUser } from '@/utility/login';
import { toDate } from '@/utility/date';

const keyYmd = 'ymd';
const keyBCode = 'bCode';

export function getStoreTargetDate(store: Store<any>): Date {
  const storeDate = toDate(store.state.ymd as Date);
  const sessionDate = new Date(sessionStorage.getItem(keyYmd) ?? new Date().toDateString());
  const res = storeDate.getTime() === sessionDate.getTime() ? storeDate : sessionDate;
  return res;
}

export function setStoreTargetDate(store: Store<any>, value: Date) {
  store.commit(keyYmd, toDate(value));
  sessionStorage.setItem(keyYmd, value.toDateString());
}

export function getStoreBCode(store: Store<any>): string {
  return store.state.bCode.length > 0
    ? store.state.bCode
    : (sessionStorage.getItem(keyBCode) ?? '').length > 0
    ? sessionStorage.getItem(keyBCode)
    : getLoginUser()?.staffMainBaseCode;
}

export function setStoreBCode(store: Store<any>, value: string) {
  store.commit(keyBCode, value);
  sessionStorage.setItem(keyBCode, value);
}
